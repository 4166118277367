import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VForm,
    {
      ref: "passwordForm",
      attrs: { "lazy-validation": _vm.lazy },
      on: {
        submit: function ($event) {
          $event.stopPropagation()
          $event.preventDefault()
          return _vm.checkPasswordForm()
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c(
        VCardText,
        [
          _vm.$route.query.firstLogin
            ? _c(
                VAlert,
                { attrs: { dense: "", color: "primary", type: "info" } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("first_login_password_redefinition")) +
                      " "
                  ),
                ]
              )
            : _vm._e(),
          _c(VTextField, {
            staticClass: "my-input-style",
            attrs: {
              label: _vm.$t("current_password"),
              "append-icon": _vm.show1 ? "mdi-eye" : "mdi-eye-off",
              rules: [_vm.required],
              type: _vm.show1 ? "text" : "password",
              "data-test": "Settings:ChangePassword:CurrentPassword",
            },
            on: {
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "space", 32, $event.key, [
                    " ",
                    "Spacebar",
                  ])
                ) {
                  return null
                }
                $event.preventDefault()
              },
              "click:append": function ($event) {
                _vm.show1 = !_vm.show1
              },
            },
            model: {
              value: _vm.currentPassword,
              callback: function ($$v) {
                _vm.currentPassword = $$v
              },
              expression: "currentPassword",
            },
          }),
          _c(VTextField, {
            ref: "password",
            staticClass: "my-input-style",
            attrs: {
              label: _vm.$t("new_password"),
              rules: [
                function (v) {
                  return (
                    (!!v && _vm.verifyAllPasswordRequirements()) ||
                    _vm.required()
                  )
                },
              ],
              "append-icon": _vm.show2 ? "mdi-eye" : "mdi-eye-off",
              type: _vm.show2 ? "text" : "password",
              "data-test": "Settings:ChangePassword:NewPassword1",
            },
            on: {
              keyup: _vm.verifyRequirements,
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "space", 32, $event.key, [
                    " ",
                    "Spacebar",
                  ])
                ) {
                  return null
                }
                $event.preventDefault()
              },
              "click:append": function ($event) {
                _vm.show2 = !_vm.show2
              },
            },
            model: {
              value: _vm.password,
              callback: function ($$v) {
                _vm.password = $$v
              },
              expression: "password",
            },
          }),
          _c(VTextField, {
            staticClass: "my-input-style",
            attrs: {
              label: _vm.$t("re_password"),
              "append-icon": _vm.show3 ? "mdi-eye" : "mdi-eye-off",
              rules: [_vm.required, _vm.passwordsMatch],
              type: _vm.show3 ? "text" : "password",
              "data-test": "Settings:ChangePassword:NewPassword2",
            },
            on: {
              "click:append": function ($event) {
                _vm.show3 = !_vm.show3
              },
            },
            model: {
              value: _vm.rePassword,
              callback: function ($$v) {
                _vm.rePassword = $$v
              },
              expression: "rePassword",
            },
          }),
          _c("ValidateStrengthPassword", {
            attrs: { userPassword: _vm.userPassword },
          }),
          _c("PasswordChecklist", {
            ref: "passwordChecklist",
            attrs: { password: _vm.password },
          }),
          _c(
            VCardActions,
            [
              _c(VSpacer),
              _c(
                VBtn,
                {
                  staticClass: "mr-0",
                  attrs: {
                    color: "primary",
                    type: "submit",
                    "min-width": "100",
                    loading: _vm.loading,
                    "data-test": "Settings:ChangePassword:Save",
                  },
                },
                [_vm._v(_vm._s(_vm.$t("save")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }