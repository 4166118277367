import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.requirements, function (item, i) {
      return _c(
        "span",
        { key: i, staticClass: "requirements" },
        [
          item.value == true
            ? _c(VIcon, { attrs: { color: "primary" } }, [
                _vm._v("mdi-check"),
              ])
            : _vm._e(),
          item.value == false && _vm.password == ""
            ? _c(VIcon, { attrs: { color: "black" } }, [
                _vm._v("mdi-circle-small"),
              ])
            : _vm._e(),
          item.value == false && _vm.password != ""
            ? _c(VIcon, { attrs: { color: "red" } }, [_vm._v("mdi-close")])
            : _vm._e(),
          _vm._v(_vm._s(_vm.$t(item.text)) + " "),
          _c("br"),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }