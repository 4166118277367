import { VAlert } from 'vuetify/lib/components/VAlert';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c(
        VContainer,
        { attrs: { id: "add-company", fluid: "", tag: "section" } },
        [
          _c(
            "base-material-card",
            {
              staticClass: "px-5 py-3",
              attrs: {
                color: "secondary",
                icon: "mdi-cog",
                title: _vm.$t("settings"),
              },
            },
            [
              _c(
                VTabs,
                {
                  on: { change: _vm.reset_alert },
                  model: {
                    value: _vm.tabs,
                    callback: function ($$v) {
                      _vm.tabs = $$v
                    },
                    expression: "tabs",
                  },
                },
                [
                  _c(VTab, [_vm._v(_vm._s(_vm.$t("change_password")))]),
                  _c(VTab, [_vm._v(_vm._s(_vm.$t("delete_account")))]),
                ],
                1
              ),
              _vm.tabs == 0
                ? _c("ChangePassword", { on: { alert: _vm.show_alert } })
                : _vm._e(),
              _vm.tabs == 1
                ? _c("DeleteAccount", { on: { alert: _vm.show_alert } })
                : _vm._e(),
              _vm.alert_msg != null
                ? _c(
                    VAlert,
                    {
                      staticClass: "ma-2",
                      attrs: { type: _vm.success ? "success" : "error" },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t(_vm.alert_msg)) + " ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _c(
        VContainer,
        [
          _c(VProgressCircular, {
            staticStyle: { "margin-left": "50%" },
            attrs: { indeterminate: "", size: "70", color: "primary" },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }